import { Modal, Form, Input, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Col, Row } from "react-bootstrap";

function Modal1(props: any) {
  //   const formLayout = {
  //     labelCol: { span: 12 }, // Corrected
  //     wrapperCol: { span: 12 }, // Corrected
  //   };
  return (
    <div>
      <Modal
        open={props.open}
        onCancel={props.onCancel}
        footer={true}
        closeIcon={false}
        className="type3-Modal"
      >
        <Form>
          <div className="type3-ModalBox1">
            <Col md={12}>
              <Row>
                <Col md={6} className="d-none- d-sm-block">
                  <div className="type3-ModalBox2">
                    <div className="type3-ModalTxt1">Let's Connect</div>
                    <div className="type3-ModalTxt2">
                      For More Enquiries, use the below contact
                    </div>
                    {/* {} */}
                    {/* <div className="type3-ModalTxt3" style={{ marginTop: 10 }}>
                      Singapore
                    </div>
                    <div className="type3-ModalTxt4">
                      9 Raffles Place, Republic Plaza, #31-02, Singapore
                      <br />
                      048619
                      <br />
                      Tel: +971 4 585 0978 Email: admindubai@arkanmars.com
                    </div> */}
                    {/* {} */}
                    <div className="type3-ModalTxt3">Dubai</div>
                    <div className="type3-ModalTxt4">
                      Office - 1305 & 1306, Dome Tower, <br />
                      Cluster N, Jumeira Lake Towers, <br />
                      P.O.Box 643949, Dubai, United Arab Emirates
                      <br />
                      Tel: +971 4 585 0978 Email: admindubai@arkanmars.com{" "}
                    </div>
                    {/* <div className="type3-ModalTxt3">Geneva</div>
                    <div className="type3-ModalTxt4">
                      5 Rue Rousseau, 1201 Geneva, Switzerland <br />
                      Tel: +41 22 508 4700 Emol: enquiries@wellbred.com{" "}
                    </div>
                    <div className="type3-ModalTxt3">Lagos</div>
                    <div className="type3-ModalTxt4">
                      6 Broad Street, Lagos, Lagos State, Nigeria
                      <br />
                      Email enquiries@wellbred.com
                    </div> */}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="type3-ModalBox3">
                    <Col md={12}>
                      <label className="type3-ModalLabel">
                        First & Last Name
                        <Form.Item name={"name"}>
                          <Input />
                        </Form.Item>
                      </label>
                    </Col>
                    <Col md={12}>
                      <label className="type3-ModalLabel">
                        Email
                        <Form.Item name={"email"}>
                          <Input />
                        </Form.Item>
                      </label>
                    </Col>
                    <Col md={12}>
                      <label className="type3-ModalLabel">
                        Phone Number
                        <Form.Item name={"mob"}>
                          <Input />
                        </Form.Item>
                      </label>
                    </Col>
                    <Col md={12}>
                      <label className="type3-ModalLabel">
                        Company Name (OPtional)
                        <Form.Item name={"company_name"}>
                          <Input />
                        </Form.Item>
                      </label>
                    </Col>
                    <Col md={12}>
                      <label className="type3-ModalLabel">
                        Message
                        <Form.Item name={"company_name"}>
                          <TextArea rows={4} />
                        </Form.Item>
                      </label>
                    </Col>
                    <div className="type3-ModalBox4">
                      <Button htmlType="submit" className="type3-ModalBtn">
                        Sumbit
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default Modal1;
