import "../styles.scss";
import { Row, Col } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import { FiArrowLeft } from "react-icons/fi";
import { motion } from "framer-motion";
import image1 from "../../assets/images/tanks-406908_1280.jpg";
// import Image2 from "../../assets/images/oil-2633_640.jpg";
import Image3 from "../../assets/images/ship-5777073_1280.jpg";
import Image4 from "../../assets/images/shaah-shahidh--subrrYxv8A-unsplash.jpg";
import Image5 from "../../assets/images/grant-durr-UPm28si8BRE-unsplash.jpg";
import Carousel from "react-bootstrap/Carousel";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { useRef, useState } from "react";

function Block2() {
  return (
    <>
      <div className="type3-Block2Box1">
        <Row className="g-0">
          <Col md={6} className="type3-BlockCol1">
            <div className="type3-Block2Box2">
              <motion.div style={{ width: "84%" }}>
                <motion.div
                  initial={{ y: 40, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.6,
                  }}
                  className="type3-Block2Txt1"
                >
                  Risk Assessment & Management
                </motion.div>
                <motion.div
                  initial={{ y: -400, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0,
                    duration: 0.7,
                  }}
                  className="type3-Block2Txt2"
                >
                  The company specializes in trading and blending gasoil and
                  gasoline, meticulously ensuring quality and meeting customer
                  specifications. Their highly skilled teams collaborate closely
                  in trading, operations, and quality control. They also deliver
                  precise LPG mixtures as per specifications. Additionally, a
                  dedicated risk analysis team monitors and trades in various
                  commodities online, aiming to boost profits and minimize
                  losses due to market risks.The company offers chartering
                  services for vessel owners, providing flexibility in time and
                  voyage options. They have a strategic presence in MEA
                  locations, facilitating efficient commodity logistics. With
                  around 70,000 cubic meters of storage capacity at various UAE
                  ports, they can handle spot cargo and component blending needs
                  with ease.
                </motion.div>
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  className="type3-Block2Box3"
                >
                  <button className="type3-Block2Btn">
                    BE A ARKAN TRADER
                  </button>
                </motion.div>
              </motion.div>
            </div>
          </Col>
          <Col md={6}>
            <div className="type3-Block2Box4">
              <div className="type3-Block2Box5">
                <Carousel data-bs-theme="dark">
                  <Carousel.Item>
                    <div className="type3-Block2Box6">
                      <img
                        className="aboutGroup-Img d-block w-100 h-100"
                        src={Image3}
                        // style={{ objectFit: "cover" }}
                        alt="Logistics"
                      />
                    </div>

                    <Carousel.Caption>
                      <h5 className="aboutGrp-Txt1">Logistics</h5>
                      <p className="aboutGrp-Txt2">
                        With our offices located in prime locations of MEA and
                        Multinational companies as our stakeholders, we can
                        cater commodities to different locations within the time
                        frame.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="type3-Block2Box6">
                      <img
                        className=" aboutGroup-Img d-block w-100 h-100"
                        src={image1}
                        // style={{ objectFit: "cover" }}
                        alt="Storage"
                      />
                    </div>

                    <Carousel.Caption>
                      <h5 className="aboutGrp-Txt1">Storage</h5>
                      <p className="aboutGrp-Txt2">
                        With nearly 70,000CBM capacity of leased storge facility
                        in various Terminals in the different ports of UAE, we
                        are able to operate and deliver with flexibility be it
                        for the spot cargo or blending the components
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="type3-Block2Box6">
                      <img
                        className="aboutGroup-Img d-block w-100 h-100"
                        src={Image5}
                        alt="Second slide"
                      />
                    </div>

                    <Carousel.Caption>
                      <h5 className="aboutGrp-Txt1">Gasoline</h5>
                      <p className="aboutGrp-Txt2">
                        Seamless gasoline trading and blending, delivering exact
                        customer specifications through a skilled, collaborative
                        team.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="type3-Block2Box6">
                      <img
                        className="aboutGroup-Img d-block w-100 h-100"
                        src={Image4}
                        alt="Second slide"
                      />
                    </div>

                    <Carousel.Caption>
                      <h5 className="aboutGrp-Txt1">Gasoline</h5>
                      <p className="aboutGrp-Txt2">
                        Our comprehensive transport services encompass
                        chartering vessels for Time and Voyage options, ensuring
                        seamless access to vessel owners. With a strategic
                        presence in MEA and robust multinational collaborations,
                        we deliver commodities promptly to various destinations.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Block2;
