import { Row, Col } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiOutlinePushpin } from "react-icons/ai";

function Footer() {
  return (
    <>
      <div className="Type3-Footer">
        <Row className="g-0">
          <Col md={4} sm={6} xs={6}>
            <div className="type3-FooterBox1">
              <div className="type3-FooterBox2">
                <img className="type3-FooterImg" src={Logo} alt="" />
              </div>
              <div className="type3-FooterBox3">
                <div>
                  <a
                    href={
                      "https://www.facebook.com/profile.php?id=61554762701167"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <BsFacebook size={26} color="red" />
                  </a>
                </div>
                <div>
                  <a
                    href={"https://twitter.com/ArkanMars"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillTwitterCircle size={30} color="red" />
                  </a>
                </div>
                <div>
                  <a
                    href={"https://www.instagram.com/arkan_mars_/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillInstagram size={30} color="red" />
                  </a>
                </div>
                <div>
                  <a
                    href={
                      "https://www.linkedin.com/company/arkan-mars-petroleum-dmcc"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillLinkedin size={28} color="red" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col md={2} sm={6} xs={6}>
            <div className="type3-FooterBox4">
              <div>
                <div className="type3-FooterTxt1">Home</div>
                <div className="type3-FooterTxt2">About us</div>
                <div className="type3-FooterTxt2">Divison</div>
                <div className="type3-FooterTxt2">Terms and condition </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} xs={6}>
            <div className="type3-FooterBox5">
              <div className="type3-FooterBox6">
                <div className="type3-FooterText3">Our Office Location</div>
                <div className="type3-FooterText4">
                  Office - 1305 & 1306, Dome Tower, Cluster N, Jumeira Lake
                  Towers, P.O.Box 643949, Dubai, United Arab Emirates
                </div>
              </div>
              <div className="type3-FooterBox7">
                <div className="type3-FooterText3"> Send Email</div>
                <div className="type3-FooterText4">
                  admindubai@arkanmars.com
                </div>
              </div>
            </div>
          </Col>
          <Col md={3} sm={6} xs={6}>
            <div className="type3-FooterBox5">
              <div className="type3-FooterBox7">
                <div className="type3-FooterText3"> Send Email</div>
                <div className="type3-FooterText4">
                  admindubai@arkanmars.com
                </div>
              </div>
              <div className="type3-FooterBox7">
                <div className="type3-FooterText3">Monday to Friday</div>
                <div className="type3-FooterText4">10:00AM - 6:00PM</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Footer;
