import { Col, Row } from "react-bootstrap";
import COO from "../../assets/images/COO.jpg";
import { motion } from "framer-motion";

function Block4() {
  return (
    <>
      {/* <div className="type3-Block4Box1">
        <Row className="g-0">
          <Col md={4} xs={{ order: 1 }}>
            <div className="type3-Block4Box4">
              <div className="type3-Block4Box5">
                <img className="type3-Block4Img" src={COO} alt="" />
              </div>
            </div>
          </Col>
          <Col md={8} xs={{ order: 2 }}>
            <div className="type3-Block4Box2">
              <div className="type3-Block4Box3">
                <motion.div
                  initial={{ y: -150, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                  }}
                  className="type3-Block3txt1"
                >
                  A Message from the COO
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1, marginTop: 20 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block4Txt2"
                >
                  "Almost two years have passed since the worldwide spread of
                  COVID-19. We recognize the unprecedented impact this pandemic
                  has had on our employees, our company, and the communities
                  where we live and operate; it has changed the way we all live
                  and work Even with continual development of vaccines the end
                  is not in sight. Since our founding we have placed a
                  significant priority on the relationships we have developed
                  with the people who matter most, our valued customers and our
                  loyal staff and we will strive to continue to do this in the
                  difficult times in which we live
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block4Txt2"
                >
                  Decarbonization is accelerating worldwide and only those
                  companies that can develop their business symbiotically with
                  the planet will be permitted to survive. As a company we are
                  committed to enhancing our contribution to and engagement with
                  sustainable development goals and will continue to find ways
                  to minimize our ecological impact across all our business
                  activities.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block4Txt2"
                >
                  I am excited about the path ahead for the Wellbred Group, one
                  that's full of opportunities, now and for years to come. Oil
                  prices are inherently volatile and political, economic, and
                  other changes have consistently rocked the oil landscape since
                  1970. Our dedication to strong risk management and operational
                  efficiency will play a key role in helping us to manage this
                  volatility and will be the key to preserving options for
                  future growth."
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block4Txt5"
                >
                  Simon Lausch
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block4Txt6"
                >
                  Chief Operating Officer (COO)
                </motion.div>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
    </>
  );
}

export default Block4;
