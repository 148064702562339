import "../styles.scss";
import CEO from "../../assets/images/CEO.jpg";
import { Col, Row } from "react-bootstrap";
import { motion } from "framer-motion";

function Block3() {
  return (
    <>
      {/* <div className="type3-Block3Box1">
        <Row className="g-0">
          <Col md={4} className="type3-Block3Order1">
            <div className="type3-Block3Box2">
              <div className="type3-Block3Box3">
                <img className="type3-Block3Img" src={CEO} alt="" />
              </div>
            </div>
          </Col>
          <Col md={8} className="type3-Block3Order2">
            <div className="type3-Block3Box4">
              <div className="type3-Block3Box5">
                <motion.div
                  initial={{ y: -130, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    duration: 1,
                  }}
                  className="type3-Block3txt1"
                >
                  A Message from the CEO
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1, marginTop: 20 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block3Txt2"
                >
                  "Wellbred is a modern and progressive trading company that
                  utilises all the expertise at its disposals to optimize the
                  flow of energy products around the globe. Through all our
                  divisions we are driven to uphold the highest governance
                  practices to deliver solid accountable results within our
                  strict policies and guidelines
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.9,
                  }}
                  className="type3-Block3Txt2"
                >
                  I am extremely proud of our achievements so far and believe
                  our continued growth and success is possible because of the
                  people we work with and the Team we have at WELLBRED. Along
                  with my experience from working at a National Oil Company and
                  several positions at top trading houses, we have a highly
                  experienced and respected team from the industry. People who
                  can build a creative environment support the development of
                  ideas and help bring them to fruition. This drive to innovate
                  throughout the organization will enable us to take the company
                  to the next level.
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.8,
                  }}
                  className="type3-Block3Txt2"
                >
                  As Wellbred continues its development we are determined to
                  embrace our place as good corporate citizens as we support a
                  carbon neutral future. Wellbred is already involved in several
                  ESG projects and we are actively looking to further this
                  business."
                </motion.div>
              </div>
              <div className="type3-Block3Box6">
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.7,
                  }}
                  className="type3-Block3Txt5"
                >
                  Ghazi Abualsaud
                </motion.div>
                <motion.div
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{
                    duration: 0.6,
                  }}
                  className="type3-Block3Txt6"
                >
                  Chief Executive Officer (CEO)
                </motion.div>
              </div>
            </div>
          </Col>
        </Row>
      </div> */}
    </>
  );
}

export default Block3;
