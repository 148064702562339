import { Col, Container, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import { HiOutlineMenu } from "react-icons/hi";
import Logo2 from "../../assets/images/arkan-removebg-preview.png";

import {
  SlSocialFacebook,
  SlSocialTwitter,
  SlSocialLinkedin,
} from "react-icons/sl";
import { useState } from "react";
import { motion } from "framer-motion";
import Modal1 from "./modal1";
import { Drawer } from "antd";
import { useNavigate } from "react-router-dom";
import { BsFacebook } from "react-icons/bs";
import {
  AiFillGooglePlusCircle,
  AiFillLinkedin,
  AiFillTwitterCircle,
} from "react-icons/ai";

function Header(props: any) {
  const [colorChange, setColorChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const changeNavColor = () => {
    if (window.scrollY >= 600) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavColor);
  const handleClick = () => {
    setShowModal(true);
  };
  return (
    <motion.div
      initial={{ y: "-100px", opacity: 0 }}
      animate={{ y: 0, opacity: 1.5 }}
      transition={{ delay: 0.2, duration: 1 }}
      className={colorChange ? "Type2-Header-Scroll" : "Type2-Header"}
      style={{ backgroundColor: props?.bgColor }}
    >
      <Container fluid>
        <Row className="g-0">
          <Col sm={3}>
            <motion.div
              initial={{ x: "-100px", opacity: 0 }}
              animate={{ x: 0, opacity: 1.5 }}
              transition={{ delay: 0.2, duration: 1 }}
              className="Type2-HeaderBox1"
            >
              <img src={Logo} onClick={() => navigate("/")} />
            </motion.div>
          </Col>
          <Col sm={2}></Col>
          <Col sm={7}>
            <div className="Type2-HeaderBox3">
              <motion.div
                initial={{ y: "-100px", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.6,
                  duration: 3,
                  type: "spring",
                  stiffness: 150,
                }}
                className="Type2-HeaderBox2btn2"
              >
                <a href={"https://www.facebook.com/profile.php?id=61554762701167"} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                  <SlSocialFacebook />
                </a>
              </motion.div>
              <motion.div
                initial={{ y: "-100px", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.7,
                  duration: 3,
                  type: "spring",
                  stiffness: 150,
                }}
                className="Type2-HeaderBox2btn2"
              >
                <a href={"https://twitter.com/ArkanMars"} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                  <SlSocialTwitter />
                </a>
              </motion.div>
              <motion.div
                initial={{ y: "-100px", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  delay: 0.8,
                  duration: 3,
                  type: "spring",
                  stiffness: 150,
                }}
                className="Type2-HeaderBox2btn2"
              >
                <a href={"https://www.linkedin.com/company/arkan-mars-petroleum-dmcc"} style={{ color: "#fff" }} target="_blank" rel="noopener noreferrer">
                  <SlSocialLinkedin />
                </a>
              </motion.div>
              <div className="Type2-HeaderBox2">
                <motion.div
                  initial={{ y: "-100px", opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{
                    delay: 0.9,
                    duration: 3,
                    type: "spring",
                    stiffness: 150,
                  }}
                  className="Type2-HeaderBox2btn"
                  onClick={handleClick}
                >
                  CONTACT US
                </motion.div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {showModal ? (
        <Modal1 open={showModal} onCancel={() => setShowModal(false)} />
      ) : null}
      <div className="type3-headerToggle">
        <HiOutlineMenu size={38} color="white" onClick={() => setOpen(true)} />
      </div>
      <Drawer open={open} placement="right" onClose={() => setOpen(false)}>
        <div className="type3-headerDrwr1">
          <div className="" style={{ textAlign: "center" }}>
            <motion.div
              initial={{ y: "-100px", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                // delay: 0.9,
                // duration: 1,
                type: "spring",
                stiffness: 150,
              }}
              className="type3-headerDrwrTxt1"
              onClick={() => navigate("/")}
            >
              HOME
            </motion.div>
            {/* <motion.div
              initial={{ y: "-100px", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                // delay: 0.8,
                // duration: 3,
                type: "spring",
                stiffness: 150,
              }}
              className="type3-headerDrwrTxt1"
              onClick={() => navigate("/About_Group")}
            >
              ABOUT GROUP
            </motion.div> */}
            <motion.div
              initial={{ y: "-100px", opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{
                // delay: 0.7,
                // duration: 3,
                type: "spring",
                stiffness: 150,
              }}
              className="type3-headerDrwrTxt1"
            >
              DIVISIONS
            </motion.div>
            {/* <motion.div
              initial={{ y: "-100px", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.6,
                duration: 3,
                type: "spring",
                stiffness: 150,
              }}
              className="type3-headerDrwrTxt1"
              // onClick={handleClick}
            >
              CONTACT US
            </motion.div> */}
          </div>
          <div className="type3-headerDrwrImg1">
            <img className="type3-headerDrwrImg" src={Logo2} alt="" />
          </div>
          <div className="type3-FooterBox3">
            <div>
              <BsFacebook size={38} color="red" />
            </div>
            <div>
              <AiFillTwitterCircle size={44} color="red" />
            </div>
            <div>
              <AiFillGooglePlusCircle size={44} color="red" />
            </div>
            <div>
              <AiFillLinkedin size={38} color="red" />
            </div>
          </div>
        </div>
      </Drawer>
    </motion.div>
  );
}

export default Header;
